import fancybox from '@fancyapps/fancybox'

$(document).ready(function () {
    $('[data-fancybox]').fancybox();


    $('.intro').each((index, value) => {
        $('div').each((i, obj) => {
            // Tell foundation to eff off iframes
            $(obj).removeClass('responsive-embed');
        })
    })
});