// import scrollMagic from 'scrollmagic'
// import {
//     TweenMax,
//     TimelineMax
// } from 'gsap'
// import 'imports-loader?define=>false!scrollmagic/scrollmagic/uncompressed/plugins/animation.gsap'
// import 'imports-loader?define=>false!scrollmagic/scrollmagic/uncompressed/plugins/debug.addIndicators.js';

// import { TweenMax } from 'gsap'
// import { TimelineMax } from 'gsap'
// import scrollMagic from 'scrollmagic'
// import 'scrollmagic/scrollmagic/uncompressed/plugins/animation.gsap';

import ScrollMagic from 'scrollmagic'
import { TweenMax, TimelineMax } from "gsap"; // Also works with TweenLite and TimelineLite
import { ScrollMagicPluginGsap } from "scrollmagic-plugin-gsap";
ScrollMagicPluginGsap(ScrollMagic, TweenMax, TimelineMax);
import MobileDetect from 'mobile-detect'

// Disable placeholder om focus
$(document).on('click', 'input', function () {
    var $this = $(this);
    var place_val = $this.attr('placeholder');
    if (place_val != '') {
        $this.data('placeholder', place_val).removeAttr('placeholder');
    }
}).on('blur', 'input', function () {
    var $this = $(this);
    var place_val = $this.data('placeholder');
    if (place_val != '') {
        $this.attr('placeholder', place_val);
    }
});

const md = new MobileDetect(window.navigator.userAgent)
var ua = window.navigator.userAgent;
var ie11 = ua.indexOf('Trident/');

// Elements
var logoMobile = $('.logo-mobile'),
    logoDesktop = $('.logo-desktop'),
    textBg = $('.text-bg'),
    st0 = $('.st1'),
    deadlyText = $('.deadly_text'),
    titleHeader = $('.title-header'),
    arrowContainer = $('.arrow-container');


$(document).ready(() => {
    // Homepage
    if ($('.home').length && !md.mobile() && $(window).width() > 1023) {

        // Init ScrollMagic
        // MAIN CONTROLLER
        var controller = new ScrollMagic.Controller({
            globalSceneOptions: {
                triggerHook: "onLeave",
                duration: "50%"
            }
        })
        // MAIN SCENE
        var scene = new ScrollMagic.Scene({
            triggerElement: 'header',
            offset: 0
        })
        var timeline = new TimelineMax()
        // Logo Mobile
        timeline.add(TweenMax.to(logoMobile, 1, {
            css: {
                bottom: '105',
                width: '120px',
                height: '112px',
                left: '51%',
            }
        }), 0)
        // Logo Desktop
        timeline.add(TweenMax.to(logoDesktop, 1, {
            css: {
                bottom: '150px',
                width: '120px',
                height: '112px',
                left: '51%',
            }
        }), 0)
        // Text Background
        timeline.add(TweenMax.to(textBg, 1, {
            css: {
                opacity: '1',
            }
        }), 0)
        scene.setTween(timeline)
        scene.addTo(controller)




        // CONTROLLER 2
        var controller2 = new ScrollMagic.Controller({
            globalSceneOptions: {
                triggerHook: "onLeave",
                duration: "200"
            }
        })
        // MAIN SCENE 2
        var scene2 = new ScrollMagic.Scene({
            triggerElement: 'header',
            offset: 0
        })
        var timeline2 = new TimelineMax()
        // Logo script text
        timeline2.add(TweenMax.fromTo(st0, .5, {
            css: {
                opacity: '1',
                fill: '#D7B660'
            }
        }, {
            css: {
                opacity: '0',
                fill: '#ffffff'
            }
        }), 0)
        // Logo title
        timeline2.add(TweenMax.fromTo(deadlyText, .5, {
            css: {
                opacity: '1'
            }
        }, {
            css: {
                opacity: '0'
            }
        }), 0)
        // Banner Arrow
        timeline2.add(TweenMax.fromTo(arrowContainer, .5, {
            css: {
                opacity: '1'
            }
        }, {
            css: {
                opacity: '0'
            }
        }), 0)
        // Title header
        timeline2.add(TweenMax.fromTo(titleHeader, .5, {
            css: {
                opacity: '1',
                fontSize: '57px'
            }
        }, {
            css: {
                opacity: '0',
                fontSize: '10px'
            }
        }), 0)
        scene2.setTween(timeline2)
        scene2.addTo(controller2)

    }

})

if (!md.mobile()) {
    $(window).resize(() => {
        if ($(window).scrollTop()) {
            TweenMax.set('.title-header', {
                css: {
                    opacity: 0
                }
            })
            TweenMax.set('.st0', {
                css: {
                    opacity: 0
                }
            })
        }
        setTimeout(function () {
            window.location.reload();
        });
    })
}




// var scene = new scrollMagic.Scene({
//         triggerElement: 'header',
//         offset: 0
//     })
//     .setTween(TweenMax.staggerFromTo('#logo', 1, {
//         css: {
//             marginTop: '0'
//         }
//     }, {
//         css: {
//             marginTop: '800px'
//         }
//     }))
//     .addTo(controller)