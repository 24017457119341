$('#more-awards').on('click', (e) => {
    e.preventDefault();
    $('.more-awards-container').toggle()
    $('#swiper-container').toggleClass('more-awards-height')
})

$('.swiper-pagination-bullet').each((index, obj) => {
    $(obj).on('click', (e) => {
        e.preventDefault();
        $('.more-awards-container').hide()
        $('#swiper-container').removeClass('more-awards-height')
    })
})