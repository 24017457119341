import Swiper from 'swiper'
import 'swiper/dist/css/swiper.css'


var productSwiper = new Swiper('.product-slider .swiper-container', {
    // autoplay: {
    //     delay: 5000,
    //     disableOnInteraction: false,
    // },
    direction: 'vertical',
    pagination: {
        el: '.swiper-pagination',
        clickable: true,
    },
})