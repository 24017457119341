import {Printd} from 'printd'

$(document).ready(() => {

    $('#printMe').on('click', (e) => {

        const cssText = `
            .feature-slider,
            #content-block-0,
            footer,
            header,
            .social {
                display: none !important;
            }
            .main-content .heading-1 {
                font-size: 40px !important;
            }
            .main-content p {
                font-size: 20px;
                padding-bottom: 2rem;
            }
            .heading-1 {
                font-family: mrs-eaves-xl-serif, serif;
                font-weight: 400;
                font-style: normal;
                font-size: 32px;
                line-height: 1;
                display: inline-block;
                padding-bottom: 1rem;
                margin: 0;
                text-rendering: optimizeLegibility;
            }
            .heading-2 {
                text-transform: uppercase;
                font-family: alternate-gothic-no-2-d,sans-serif;
                font-weight: 400;
                font-style: normal;
                font-size: 20px;
                letter-spacing: 2px;
                text-rendering: optimizeLegibility;
            }
            ul li {
                list-style-type: none;
                display: flex;
                flex-direction: row;
                margin-bottom: 5px;
                font-family: mrs-eaves-xl-serif, serif;
                font-weight: 400;
                font-style: normal;
                font-size: 20px;
                text-rendering: optimizeLegibility;
            }

            ul li span {
                font-family: alternate-gothic-no-2-d, sans-serif;
                font-weight: 400;
                font-style: normal;
                font-size: 16px;
                letter-spacing: 2px;
                text-rendering: optimizeLegibility;
                color: #aaa086;
                text-transform: uppercase;
                display: inline-block;
                width: 130px;
            }
            ul li div {
                display: inline-block;
                font-family: mrs-eaves-xl-serif, serif;
                font-weight: 400;
                font-style: normal;
                font-size: 20px;
                line-height: 1.2 !important;
                text-rendering: optimizeLegibility;
            }
        `

        const d = new Printd()
        d.print(document.querySelector('.blog-single'), [ cssText ] )
    })

})