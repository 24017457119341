//import "@babel/polyfill";
import 'foundation-sites'
import './init-foundation'
import './wp-foundation'
import '../../styles/scss/style.scss'
import './modules/fancybox'
import './modules/store-locator'
import './modules/navMenu'
import './modules/logo'
import './modules/slider'
import './modules/awards'
import './modules/scrollMagicAnimations'
import './modules/popmotionAnimations'
import './modules/print'
// import './modules/shadows'
import './modules/contact'